import React from "react"
import { Link } from "gatsby"
import HeroImage from "../contents/Home/HeroImage"
import SvgDevices from "../contents/Home/SvgDevices"
import SvgDeadPixelRepair from "../contents/Home/SvgDeadPixelRepair"
import SvgStuckPixelRepair from "../contents/Home/SvgStuckPixelRepair"
import SEO from "../components/Seo"
const IndexPage = () => {
  return (
    <>
      <SEO title="Ana Sayfa" />
      <h1 className="pb-0 mb-0 text-center">
        <span className="text-indigo-600">Ölü</span>&nbsp;ve&nbsp;
        <span className="text-red-500">sıkışmış</span> pikselleri kontrol edin!
      </h1>

      <HeroImage className="max-w-full px-3 md:px-10" />

      <p className="lead">
        Herhangi bir kurulum gerektirmeden, tarayıcı üzerinden, cep telefonu,
        tablet, televizyon ve bilgisayarlarınızda&nbsp;
        <strong>ölü piksel</strong>&nbsp; veya{" "}
        <span className="font-bold text-green-400">sıkışmış piksel</span>
        &nbsp; testini yapabilir,{" "}
        <span className="font-bold underline">ücretsiz</span>&nbsp;
        <span className="font-bold text-purple-600">
          piksel düzeltme aracını
        </span>
        &nbsp; kullanabilirsiniz.
      </p>
      <h2>Ölü ya da Sıkışmış Piksel Testi</h2>
      <p>
        İster tablet, ister telefon, isterse monitör... Ekrana sahip herhangi
        bir cihaz aldığınızda kurulumu takiben{" "}
        <strong>ölü piksel testini</strong> ve{" "}
        <strong>sıkışmış piksel testini</strong> yapmanız gerekmektedir.
      </p>
      <p>
        Satın aldığınız üründe olası bir kusuru ne kadar erken fark ederseniz,
        iade ve değişim işlemlerini gerçekleştirmek o kadar kolay olur. Üstelik
        bu işlem çok zamanınızı almaz, birkaç dakika içerisinde kolayca ölü
        piksel ve takılmış piksel testini yapabilirsiniz.
      </p>
      <SvgDevices className="max-w-full px-3 md:px-10" />

      <p>
        Aşağıdaki <strong>ölü piksel testi</strong> düğmesine tıklayarak ilgili
        sayfaya gidip, testi başlatabilirsiniz. Açılan sayfada test aracının
        yanı sıra ölü piksel ve sıkışmış piksel testi hakkında detaylı
        bilgilendirme de mevcuttur.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/olu-piksel-testi"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Ölü Piksel Testi Yap</span>
        </Link>
      </div>
      <h2>Ölü piksel nasıl tamir edilir, ölü piksel sorunu nasıl giderilir?</h2>
      <p>
        Ölü piksel ekrandaki bir pikselin ömrünü tamamladığı anlamına gelir.
        Enerji almadığı için genelde siyah renklidir. Ölü piksel sorununu
        gidermenin yazılımsal çözümü yoktur, bunun için genelde panel, ekran
        değişimi gereklidir. LCD ekranlarda sıvı kristallerindeki yayılımdan
        kaynaklı ölü piksel varsa{" "}
        <span className="font-bold underline">
          baskı yöntemi işe yarayabilir
        </span>
      </p>
      <SvgDeadPixelRepair className="max-w-full px-3 md:px-10" />

      <p>
        Aşağıdaki <strong>Ölü Piksel Tamiri</strong> düğmesine tıklayarak
        açacağınız sayfada ölü piksellerinizi baskı yöntemi kullanarak nasıl
        giderebileceğinizi adım adım anlatılmıştır.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/olu-piksel-tamiri"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Ölü Piksel Tamiri</span>
        </Link>
      </div>
      <h2>
        Sıkışmış (takılmış) piksel nasıl tamir edilir, sıkışmış piksel sorunu
        nasıl giderilir?
      </h2>
      <p>
        Ekranınızdaki değişmeyen renkler{" "}
        <strong className="italic">
          özellikle de renkleri siyah ya da beyaz değilse
        </strong>{" "}
        her zaman ölü piksel oldukları anlamına gelmez. Uzun süre bir renkte
        kalan pikseller <span className="text-red-600">kırmızı</span>,{" "}
        <span className="text-green-400">yeşil</span>,{" "}
        <span className="text-blue-600">mavi</span> gibi renklerde
        takılabilirler.
      </p>
      <SvgStuckPixelRepair className="max-w-full px-3 md:px-10" />
      <p>
        Aşağıdaki <strong>Sıkışmış Piksel Tamiri</strong> düğmesine tıklanınca
        açılan sayfada takılmış pikseller için ücretsiz tamir aracı
        bulunmaktadır. Sıkışmış piksel tamir aracının kullanımıyla alakalı
        bilgilendirmenin yanı sıra sıkışmış pikselin ne olduğu ve sıkışmış
        pikselle ölü piksel arasındaki farka dair bilgileri de{" "}
        <code>sıkışmış piksel tamiri</code> sayfasında bulabilirsiniz.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/sikismis-piksel-tamiri"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Sıkışmış Piksel Tamiri</span>
        </Link>
        {
          // TODO Footer tasarla
          // Faydalı linkler ->
          // http://www.forumgercek.com/bilgisayar-donanim-yazilim/114672-olu-sikismis-piksel-nedir-bulutlanma-nedir.html
          // https://www.olupikseltest.com/takilmis-piksel-sorun-giderme/
          // TODO layout navbar navigation için çeviri dropdown menü yeterli
        }
      </div>
    </>
  )
}
export default IndexPage
